

.search-trigger-active {
    &.open {
        i {
            &.feather-search {
                &::before {
                    content: "\ea02";
                }
            }
        }
    }
}



.rbt-search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border-top: 1px solid #f1f0f3;
    box-shadow: 0 4px 16px rgba(8, 10, 18, 0.1);
    z-index: 104;
    visibility: hidden;
    clip: rect(0px, 200vw, 0, 0px);
    opacity: 0;
    transition: opacity 0.2s linear, clip 0.6s linear, visibility 0s 0.2s;
    transform: translateZ(0);
    @media #{$md-layout} {
        max-height: 500px;
        overflow-y: scroll;
    }
    @media #{$sm-layout} {
        max-height: 500px;
        overflow-y: scroll;
    }
    .wrapper {
        max-width: 900px;
        margin: 16px auto;
        padding: 0 16px;
        font-weight: 400;
        @media #{$md-layout} {
            margin: 15px 0;
            display: block;
            margin-top: 0;
        }
        @media #{$sm-layout} {
            margin: 15px 0;
            display: block;
            margin-top: 0;
        }
        form {
            margin: 30px 0;
            display: flex;
            @media #{$large-mobile} {
                display: block;
            }
            input {
                height: 50px;
                width: 700px !important;
                line-height: 48px;
                margin: 0 12px 0 0;
                padding: 0 16px;
                border: 2px solid var(--color-border);
                transition: 0.3s;
                @media #{$large-mobile} {
                    font-size: 14px;
                    margin-bottom: 15px;
                    width: 285px !important
                }
                &:focus {
                    border-color: var(--color-primary);
                }
            }
        }
    }

    &.active {
        visibility: visible;
        opacity: 1;
        clip: rect(0px, 200vw, 200vh, 0px);
        transition: clip 0.6s linear, opacity 0.2s linear;
    }

    .rbt-card {
        padding: 10px;
        box-shadow: var(--shadow-1);
        .rbt-card-body {
            padding-top: 18px;
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 8px;
            .rbt-card-title {
                margin-bottom: 7px;
                font-size: 16px;
            }
            .rbt-review {
                margin-bottom: 8px;
                .rating { 
                    margin-right: 5px;
                    i {
                        font-size: 9px;
                    }
                }
                .rating-count {
                    font-size: 11px;
                    margin-top: 1px;
                }
            }

            .rbt-price  {
                .current-price {
                    font-size: 14px;
                }
                .off-price {
                    font-size: 14px;
                }
            }
        }
    }
    
    .rbt-search-with-category {
        @media #{$md-layout} {
            margin-top: 15px;
        }
        @media #{$sm-layout} {
            margin-top: 15px;
        }
    }

    &.search-with-category-popup {
        @media #{$md-layout} {
            overflow-y: inherit;
        }
        @media #{$sm-layout} {
            overflow-y: inherit;
        }
    }
}



.side-nav-opened body::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 99;
    transition: all 0.7s;
}

/*-------------------------
    Search Style Default  
--------------------------*/

.rbt-search-style {
    position: relative;
    input {
        background: transparent;
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        padding-right: 60px;
        border-radius: 500px;
        min-width: 300px;
        border: 2px solid var(--color-white);
        color: var(--color-white);
        &:focus {
            border-color: var(--color-white);
        }
    }
    .rbt-search-btn {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        i {
            color: var(--color-white);
            transition: 0.3s;
        }
        &:hover {
            i {
                color: var(--color-primary);
            }
        }
    }
}

/*-------------------------
    Search Style One  
--------------------------*/
.rbt-search-style-1 {
    position: relative;
    input {
        height: 50px;
        line-height: 50px;
        padding-right: 50px;
    }
    .search-btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        background: transparent;
        padding: 0;
        border: 0 none;
        display: block;
        width: 50px;
        text-align: center;
        transition: 0.4s;
        &:hover {
            color: var(--color-primary);
        }
    }
}











